#root {
 display: flex;
 justify-content: center;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: row;
  max-width: 200px;
  margin: 20px
}

button {
  height: 50px;
  width: 50px;
  border-radius: 3px;
  margin: 5px;
  font-size: x-large;
}

.reload {
  height: fit-content;
  width: fit-content;
  border-radius: 3px;
  border: 3px solid slategrey;
  margin: 5px;
}
